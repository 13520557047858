<template>
  <div class="demo">demo</div>
</template>

<script>
export default {
  name: "Demo",
  data() {
    return {
      //
    }
  },
  components: {},
}
</script>

<style lang="scss">
@import "~@/assets/scss/init";
@import "~@/assets/scss/layouts/base";

//
</style>
